import React from 'react'
import PagePricing from '../components/pages/Pricing/Pricing'
import Layout from '../Layouts/zh'

const pricingZh = ({ location }) => {
  return (
    <Layout location={location}>
      <PagePricing />
    </Layout>
  )
}

export default pricingZh
